// 终端 参数配置 的固定参数
export const parametersData = [{ 
        id: -1,
        extraName: "终端名称",
        extraCode: null,
        status: 1,
        isEmpty: 0,
        isEdit: 0,
        isUnique: 0,
        extraType: "text",
        extraTips: "请输入",
        extraValue: null,
        count: null,
    },
    {
        id: -2,
        extraName: "终端编码",
        extraCode: null,
        status: 1,
        isEmpty: 1,
        isEdit: 1,
        isUnique: 1,
        extraType: "text",
        extraTips: "请输入,默认系统自动生成",
        extraValue: null,
        count: null,
    },
    {
        id: -3,
        extraName: "通路大类",
        extraCode: null,
        status: 1,
        isEmpty: 1,
        isEdit: 1,
        isUnique: 0,
        extraType: "text",
        extraTips: "请输入",
        extraValue: null,
        count: null,
    },
    {
        id: -4,
        extraName: "通路",
        extraCode: null,
        status: 1,
        isEmpty: 1,
        isEdit: 1,
        isUnique: 0,
        extraType: "text",
        extraTips: "请选择",
        extraValue: null,
        count: null,
    },
    {
        id: -5,
        extraName: "渠道",
        extraCode: null,
        status: 1,
        isEmpty: 0,
        isEdit: 1,
        isUnique: 0,
        extraType: "radio",
        extraTips: "请选择",
        extraValue: null,
        count: null,
    },
    {
        id: -6,
        extraName: "子渠道",
        extraCode: null,
        status: 1,
        isEmpty: 0,
        isEdit: 1,
        isUnique: 0,
        extraType: "radio",
        extraTips: "请选择",
        extraValue: null,
        count: null,
    },
    {
        id: -7,
        extraName: "省",
        extraCode: null,
        status: 1,
        isEmpty: 0,
        isEdit: 1,
        isUnique: 0,
        extraType: "text",
        extraTips: "请选择",
        extraValue: null,
        count: null,
    },
    {
        id: -8,
        extraName: "市",
        extraCode: null,
        status: 1,
        isEmpty: 0,
        isEdit: 1,
        isUnique: 0,
        extraType: "text",
        extraTips: "请选择",
        extraValue: null,
        count: null,
    },
    {
        id: -9,
        extraName: "区",
        extraCode: null,
        status: 1,
        isEmpty: 0,
        isEdit: 1,
        isUnique: 0,
        extraType: "text",
        extraTips: "请选择",
        extraValue: null,
        count: null,
    },
    {
        id: -10,
        extraName: "乡镇",
        extraCode: null,
        status: 1,
        isEmpty: 1,
        isEdit: 1,
        isUnique: 0,
        extraType: "text",
        extraTips: "请选择，若没有可选项请手动输入",
        extraValue: null,
        count: null,
    },
    {
        id: -11,
        extraName: "纬度",
        extraCode: null,
        status: 1,
        isEmpty: 1,
        isEdit: 0,
        isUnique: 0,
        extraType: "text",
        extraTips: "自动根据地址生成",
        extraValue: null,
        count: null,
    },
    {
        id: -12,
        extraName: "经度",
        extraCode: null,
        status: 1,
        isEmpty: 1,
        isEdit: 0,
        isUnique: 0,
        extraType: "text",
        extraTips: "自动根据地址生成",
        extraValue: null,
        count: null,
    },
    {
        id: -13,
        extraName: "规模",
        extraCode: null,
        status: 1,
        isEmpty: 1,
        isEdit: 0,
        isUnique: 0,
        extraType: "radio",
        extraTips: "请选择",
        extraValue: null,
        count: null,
    },
    {
        id: -14,
        extraName: "联系电话",
        extraCode: null,
        status: 1,
        isEmpty: 1,
        isEdit: 1,
        isUnique: 0,
        extraType: "text",
        extraTips: "请输入",
        extraValue: null,
        count: null,
    },
];

// 终端 终端列表的固定表格第一行
export const terminalListColumns = [{
            title: "#",
            dataIndex: "id",
            slots: { customRender: "serialNumber" },
            width: 70,
            fixed: 'left',
        },
        {
            title: "终端名称/编码",
            dataIndex: "terminalName",
            slots: { customRender: "terminalName" },
            fixed: 'left',
            width: 250,
        },
        {
            title: "终端来源标识",
            dataIndex: "terminalOriginCode",
            slots: { customRender: "terminalOriginCode" },
            width: 150,
            ellipsis: true,
        },
        {
            title: "终端来源描述",
            dataIndex: "terminalOriginMemo",
            slots: { customRender: "terminalOriginMemo" },
            width: 150,
            ellipsis: true,
        },
        // {
        //     title: "通路大类",
        //     dataIndex: "kindsItem1",
        //     width: 100,
        // },
        // {
        //     title: "通路",
        //     dataIndex: "kindsItem2",
        //     width: 100,
        // },
        {
            title: "渠道",
            dataIndex: "channelName",
            width: 100,
        },
        // {
        //     title: "子渠道",
        //     dataIndex: "childChannel",
        //     width: 100,
        // },
        // {
        //   title: "省",
        //   dataIndex: "province",
        //   slots: { customRender: "province" },
        //   width: 60,
        // },
        // {
        //   title: "市",
        //   dataIndex: "city",
        //   slots: { customRender: "city" },
        //   width: 60,
        // },
        // {
        //   title: "区",
        //   dataIndex: "area",
        //   slots: { customRender: "area" },
        //   width: 80,
        // },
        {
            title: "地址",
            dataIndex: "address",
            slots: { customRender: "province" },
            width: 250,
        },
        {
            title: "规模类型",
            dataIndex: "scaleType",
            slots: { customRender: "scaleType" },
            width: 100,
        },
        // {
        //     title: "联系电话",
        //     dataIndex: "phone",
        //     width: 100,
        // },
    ]
    //  参数设置的固定表头
export const parametersColumns = [{
        title: "#",
        dataIndex: "id",
        slots: { customRender: "serialNumber" },
        width: 100,
        fixed: 'left',
        align: "left",
    },
    {
        title: "参数名称",
        dataIndex: "extraName",
        align: "left",
        width: 160,
    },
    {
        title: "使用",
        dataIndex: "status",
        slots: { customRender: "status" },
        align: "center",
        width: 110,

    },
    {
        title: "必填",
        dataIndex: "isEmpty",
        slots: { customRender: "isEmpty" },
        align: "center",
        width: 110,

    },
    {
        title: "可修改",
        dataIndex: "isEdit",
        slots: { customRender: "isEdit" },
        align: "center",
        width: 110,

    },
    {
        title: "唯一性",
        dataIndex: "isUnique",
        slots: { customRender: "isUnique" },
        align: "center",
        width: 110,

    },
    {
        title: "参数格式",
        dataIndex: "extraType",
        slots: { customRender: "extraType" },
        align: "left",
        width: 140,

    },
    {
        title: "提示信息",
        dataIndex: "extraTips",
        align: "left",
        width: 220,
    },
    {
        title: "操作",
        slots: { customRender: "operation" },
        align: "center",
        width: 100,
    },
];


//单品 columns  单品的固定表格第一行
export const singleProductColumns = [{
        title: "#",
        dataIndex: "id",
        slots: { customRender: "serialNumber" },
        width: 70,
        fixed: 'left',
    },
    {
        title: "单品名称/编码",
        dataIndex: "spName",
        slots: { customRender: "spName" },
        fixed: 'left',
        width: 250,
    },
    {
        title: "单品简称",
        dataIndex: "spNickName",
        width: 150,
    },
    {
        title: "单品条形码",
        dataIndex: "spBarCode",
        slots: { customRender: "spBarCode" },
        width: 150,
    },
    {
        title: "单品图片",
        dataIndex: "spBarCode",
        slots: { customRender: "spImage" },
        width: 72,
    },
    {
        title: "品类",
        dataIndex: "categoryName",
        width: 100,
    },
    {
        title: "品牌",
        dataIndex: "brandName",
        width: 100,
    },
    {
        title: "单位",
        dataIndex: "stockUnitCode",
        width: 100,
    },
    {
        title: "零售价（元）",
        dataIndex: "salesPrice",
        width: 100,
    },
    {
        title: "包装尺寸",
        dataIndex: "packageSize",
        slots: { customRender: "packageSize" },
        width: 100,
    },
    {
        title: "保质期（天）",
        dataIndex: "shelfLife",
        width: 100,
    },
    // {
    //   title: "操作",
    //   slots: { customRender: "handle" },
    //   align: "left",
    //   fixed: "right",
    //   width: "150px",
    // }
]


//品牌管理  column
export const brandColumns = [{
        title: "#",
        dataIndex: "id",
        slots: { customRender: "serialNumber" },
        width: 80,
        align: 'center',
        fixed: 'left',
    },
    {
        title: "品牌名称",
        dataIndex: "brandName",
        slots: { customRender: "brandName" },
        fixed: 'left',
        align: 'left',
        width: 300,
    },
    {
        title: "品牌logo",
        dataIndex: "imgLogo",
        slots: { customRender: "logo" },
        width: 280,
    },
    {
        title: "品牌介绍",
        dataIndex: "memo",
        slots: { customRender: "memo" },
        width: 440,
    }
]

//单品详情 里面items的 columns
export const singleProductItemsColumns = [{
        title: "单品名称/编码",
        dataIndex: "spName",
        slots: { customRender: "spName" },
        fixed: 'left',
        // width:'150'
        align: 'center'
    },
    {
        title: "单品条形码",
        dataIndex: "spBarCode",
        slots: { customRender: "spBarCode" },
    },
    {
        title: "单品简称",
        dataIndex: "spNickName",
    },
    {
        title: "品类",
        dataIndex: "categoryName",
    },
    {
        title: "品牌",
        dataIndex: "brandName",
    },
    {
        title: "零售价（元）",
        dataIndex: "salesPrice",
    },
    {
        title: "单位",
        dataIndex: "stockUnitCode",
    },
]

//单品的固定自定义字段
export const singleProductParametersData = [{
        id: -1,
        extraName: "单品名称",
        extraCode: null,
        status: 1,
        isEmpty: 0,
        isEdit: 1,
        isUnique: 1,
        extraType: "text",
        extraTips: "请输入",
        extraValue: null,
        count: null,
    },
    {
        id: -2,
        extraName: "单品条形码",
        extraCode: null,
        status: 1,
        isEmpty: 0,
        isEdit: 1,
        isUnique: 1,
        extraType: "text",
        extraTips: "请输入",
        extraValue: null,
        count: null,
    },
    {
        id: -3,
        extraName: "单品简称",
        extraCode: null,
        status: 1,
        isEmpty: 1,
        isEdit: 1,
        isUnique: 0,
        extraType: "text",
        extraTips: "请输入",
        extraValue: null,
        count: null,
    },
    {
        id: -4,
        extraName: "品类",
        extraCode: null,
        status: 1,
        isEmpty: 0,
        isEdit: 1,
        isUnique: 0,
        extraType: "radio",
        extraTips: "请选择",
        extraValue: null,
        count: null,
    },
    {
        id: -5,
        extraName: "品牌",
        extraCode: null,
        status: 1,
        isEmpty: 0,
        isEdit: 1,
        isUnique: 0,
        extraType: "radio",
        extraTips: "请选择",
        extraValue: null,
        count: null,
    },
    {
        id: -6,
        extraName: "单位",
        extraCode: null,
        status: 1,
        isEmpty: 0,
        isEdit: 1,
        isUnique: 0,
        extraType: "radio",
        extraTips: "请选择",
        extraValue: null,
        count: null,
    },
    {
        id: -7,
        extraName: "零售价",
        extraCode: null,
        status: 1,
        isEmpty: 0,
        isEdit: 1,
        isUnique: 0,
        extraType: "number",
        extraTips: "请输入",
        extraValue: null,
        count: null,
    },
    {
        id: -8,
        extraName: "保质期",
        extraCode: null,
        status: 1,
        isEmpty: 1,
        isEdit: 1,
        isUnique: 0,
        extraType: "number",
        extraTips: "请输入",
        extraValue: null,
        count: null,
    },
    {
        id: -9,
        extraName: "长度",
        extraCode: null,
        status: 1,
        isEmpty: 1,
        isEdit: 1,
        isUnique: 0,
        extraType: "number",
        extraTips: "请输入",
        extraValue: null,
        count: null,
    },
    {
        id: -10,
        extraName: "宽度",
        extraCode: null,
        status: 1,
        isEmpty: 1,
        isEdit: 1,
        isUnique: 0,
        extraType: "number",
        extraTips: "请输入",
        extraValue: null,
        count: null,
    },
    {
        id: -11,
        extraName: "高度",
        extraCode: null,
        status: 1,
        isEmpty: 1,
        isEdit: 1,
        isUnique: 0,
        extraType: "number",
        extraTips: "请输入",
        extraValue: null,
        count: null,
    },
    {
        id: -12,
        extraName: "单品图片",
        extraCode: null,
        status: 1,
        isEmpty: 1,
        isEdit: 1,
        isUnique: 0,
        extraType: "text",
        extraTips: "",
        extraValue: null,
        count: null,
    }
];

//客户 columns  客户的固定表格第一行
export const custColumns = [{
        title: "#",
        dataIndex: "id",
        slots: { customRender: "serialNumber" },
        width: 70,
        fixed: 'left',
    },
    {
        title: "客户名称/编码",
        dataIndex: "custName",
        slots: { customRender: "custName" },
        fixed: 'left',
        width: 150,
    },
    {
        title: "社会信用统一编码",
        dataIndex: "unifiedCode",
        slots: { customRender: "unifiedCode" },
        width: 150,
    },
    {
        title: "地址",
        dataIndex: "address",
        width: 250,
    },
    {
        title: "客户类型",
        dataIndex: "custType",
        width: 100,
    }
]